const key = "nkScuhFLWK8eWGAyZg7U"
const geopathsInput = document.querySelector("#geopaths") 
const excludeUserInput = document.querySelector("#excludeUser") 
const btnSubmit = document.querySelector(".js-submit")
const btnClear = document.querySelector(".js-clear")

const shareWrapper = document.querySelector(".c-share") 
const shareInput = shareWrapper.querySelector("#shareLink") 
const btnCopyLink = document.querySelector(".js-copyLink")

let map, cachesLayer, uuid, iconType

if ('scrollRestoration' in history) history.scrollRestoration = 'manual'

const createMap = () => {
    let marker
    let circle

    map = L.map('map').setView([51.9415714140497, 19.372374589469647], 7);
    L.control.scale({
        imperial: false,
        maxWidth: 300,
        position: 'bottomright'
    }).addTo(map);
    
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);
    mapActions(map)
    cachesLayer = L.featureGroup().addTo(map);    

    const onLocationFound  = (e) => {
        const radius = e.accuracy / 2;

        if (marker) {
            marker.setLatLng(e.latlng);
            circle.setLatLng(e.latlng).setRadius(radius);
        } else {
            marker = L.marker(e.latlng).addTo(map)    
            circle = L.circle(e.latlng, radius).addTo(map);
        }
    }        

    map.on('locationfound', onLocationFound);
}

const mapActions = (map) => {
    const html = document.documentElement
    const btnFullMap = document.querySelector(".js-fullMap")
    const btnSmallMap = document.querySelector(".js-smallMap")
    const btnMyLocation = document.querySelector(".js-myLocation")
    let positionMarker
    let accuracyCircle

    btnFullMap.addEventListener("click", () => {
        html.classList.add("-mapFullScreen")
        map.invalidateSize();
    })

    btnSmallMap.addEventListener("click", () => {
        html.classList.remove("-mapFullScreen")
        map.invalidateSize();
    })

    btnMyLocation.addEventListener("click", () => {
        map.locate({setView: true, maxZoom: 16});
        // const lat = position.coords.latitude
        // const long = position.coords.longitude
        // const accuracy = position.coords.accuracy        

        // if (!positionMarker || !accuracyCircle) {
        //     positionMarker = L.marker([lat, long])
        //     accuracyCircle = L.circle([lat, long], {radius: accuracy})

        //     L.featureGroup([positionMarker, accuracyCircle]).addTo(map)
        // } else {
        //     positionMarker.setLatLng([lat, long]);
        //     accuracyCircle.setLatLng([lat, long]);
        // }

        // navigator.geolocation.getCurrentPosition(getPosition)
    })
}

const addMarker = (cache, item, isDisabled, pathIndex, path) => {
    const latlng = cache.location.split("|")     
    const iconAdditionalClasses = `${item} -p${pathIndex+1} ${isDisabled ? "-disabled" : null}` 

    const iconTradycyjna = L.divIcon({className: "c-markerIcon -iconTradycyjna "+iconAdditionalClasses})
    const iconNietypowa = L.divIcon({className: "c-markerIcon -iconNietypowa "+iconAdditionalClasses})
    const iconQuizowa = L.divIcon({className: "c-markerIcon -iconQuizowa "+iconAdditionalClasses})
    const iconMulti = L.divIcon({className: "c-markerIcon -iconMulti "+iconAdditionalClasses})
    const iconVirtualna = L.divIcon({className: "c-markerIcon -iconVirtualna "+iconAdditionalClasses})
    const iconMobilna = L.divIcon({className: "c-markerIcon -iconMobilna "+iconAdditionalClasses})

    if (cache.type == "Traditional") iconType = iconTradycyjna
    if (cache.type == "Multi") iconType = iconMulti
    if (cache.type == "Quiz") iconType = iconQuizowa
    if (cache.type == "Other") iconType = iconNietypowa
    if (cache.type == "Virtual") iconType = iconVirtualna
    if (cache.type == "Moving") iconType = iconMobilna

    const popup = L.popup({
        autoClose: true,
        className: isDisabled ? "-disabled" : null,
        content: 
            `
            <div class="c-tooltipCta">
                <a class="c-tooltipCta_link" href="${cache.url}" target="_blank">${cache.name}</a> 
                <a class="c-tooltipCta_path" href="https://opencaching.pl/powerTrail.php?ptAction=showSerie&ptrail=${path}" target="_blank">Geościeżka</a> 
                <a class="c-tooltipCta_navigate" href="https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${(cache.location).replace("|",",")}" target="_blank">Nawiguj</a>                
                <button class="js-hide c-tooltipCta_hide" data-target="${item}">Ukryj</button>
                <button class="js-show c-tooltipCta_show" data-target="${item}">Pokaż</button>
                <a class="c-tooltipCta_write" href="https://opencaching.pl/log.php?cacheid=${cache.internal_id}" target="_blank">Wpis do logu</a> 
            </div>
            ` 
        })

    L.marker(latlng, {icon: iconType})
        .addTo(cachesLayer)
        .bindPopup(popup)
}

const main = async () => {
    if (geopathsInput.validity.patternMismatch) {
        geopathsInput.classList.add("is-invalid")
        return
    }

    makeshareLink()
    
    Cookies.set('geopaths', geopathsInput.value)
    Cookies.set('user', excludeUserInput.value)

    const cachesInfo = document.querySelector(".c-cachesInfo")
    const cachesCounterWrapper = document.querySelector(".c-counter")
    const cachesCounterValue = cachesCounterWrapper.querySelector("span")
    const geopaths = (geopathsInput.value).split(",")
    const geopathsQuantity = geopaths.length
    const user = excludeUserInput.value
    const disabledArray = Cookies.get('disabledList') ? Cookies.get('disabledList').split(",") : []
    let counter = 0
    let pathIndex = 0        
    
    geopathsInput.classList.remove("is-invalid")
    cachesCounterWrapper.classList.remove("d-none")
    cachesLayer.clearLayers() 

    fetch('https://opencaching.pl/okapi/services/users/by_username?consumer_key='+key+'&username='+user+'&fields=uuid')
        .then(user => user.json())
        .then(user => {
            uuid = user.uuid
            cachesInfo.innerHTML = ""

            const pathActions = (index) => {
                const path = geopaths[index]
                
                fetch('https://opencaching.pl/okapi/services/caches/search/all?consumer_key='+key+'&powertrail_ids='+path+'&limit=500')
                    .then(caches => caches.json())
                    .then(caches => {
                        const cachesResults = caches.results
                        const cachesQuantity = cachesResults.length
                        let i = 0
                        let j = 0       
    
                        let intervalCounter = 0
                        const intervalRatio = 5
    
                        const interval = setInterval(() => {
                            for(let u = (intervalRatio*intervalCounter); (u < ((intervalCounter+1)*intervalRatio) && u < cachesQuantity); u+=1) {    
                                const item = cachesResults[u]
                                const url = uuid 
                                    ? 'https://opencaching.pl/okapi/services/caches/geocache?consumer_key='+key+'&cache_code='+item+'&fields=name|location|type|url|internal_id|is_found&user_uuid='+uuid
                                    : 'https://opencaching.pl/okapi/services/caches/geocache?consumer_key='+key+'&cache_code='+item+'&fields=name|location|type|url|internal_id'
    
                                fetch(url)
                                    .then(cache => cache.json())
                                    .then(cache => {
                                        const geocache = cache
                                        let isDisabled = false
                                        isDisabled = disabledArray.find(element => element == item)
                                        const listClass = isDisabled ? item + " -disabled" : item
    
                                        const createCache = () => {   
                                            if (j == 0) {                                    
                                                cachesInfo.innerHTML += `
                                                    <strong class="-p${index + 1}" style="order: ${index}"><a href="https://opencaching.pl/powerTrail.php?ptAction=showSerie&ptrail=${path}" target="_blank">Ścieżka nr ${index + 1} (id: ${path})</a></strong>
                                                    <div class="${listClass}" style="order: ${index}">
                                                        <a href="${geocache.url}" target="_blank">[${item}] - ${geocache.name}</a>
                                                        <button class="js-show" data-target="${item}"></button>
                                                        <button class="js-hide" data-target="${item}"></button>
                                                    </div>
                                                `
                                            }
                                            if (j > 0) {
                                                cachesInfo.innerHTML += `
                                                    <div class="${listClass}" style="order: ${index}">
                                                        <a href="${geocache.url}" target="_blank">[${item}] - ${geocache.name}</a>
                                                        <button class="js-show" data-target="${item}"></button>
                                                        <button class="js-hide" data-target="${item}"></button>
                                                    </div>
                                                `
                                            }
                                            addMarker(geocache, item, isDisabled, index, path)
                                            j++
                                            counter++
                                        } 
    
                                        if (uuid) {
                                            if (!geocache.is_found) createCache()
                                        } else createCache()
    
                                        cachesCounterValue.innerHTML = counter                
                                        i++
                                        map.fitBounds(cachesLayer.getBounds())
                                        map.locate() 
                                    })         
    
                                if (u >= (cachesQuantity-1)) {                                    
                                    pathIndex+=1
                                    console.log(pathIndex)
                                    if (pathIndex <= geopathsQuantity) pathActions(pathIndex)
                                    clearInterval(interval)
                                }                                    
                            }
                            intervalCounter += 1
                        }, 1100)   
                    })     
            }
            pathActions(pathIndex)
            
        })  

    map.on('popupopen', e => {
        const marker = e.popup._source
        const popupContainer = e.popup._container
        const isDisabled = marker._icon.classList.contains("-disabled");

        isDisabled ? popupContainer.classList.add("-disabled") : popupContainer.classList.remove("-disabled")
    })
}

const btnSubmitActivity = () => {
    geopathsInput.value ? btnSubmit.removeAttribute("disabled") : btnSubmit.setAttribute("disabled","true")
}

const valueFiltering = e => {
    if ((e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105) && e.keyCode != 8 && e.keyCode != 17 && e.keyCode != 32 && e.keyCode != 35 && e.keyCode != 36 && e.keyCode != 37 && e.keyCode != 38 && e.keyCode != 39 && e.keyCode != 40 && e.keyCode != 46 && e.keyCode != 188) e.preventDefault()
}

const makeshareLink = () => {
    const url = new URL(window.location.href)
    const searchParams = url.searchParams
    if (geopathsInput.value) searchParams.set("ids",geopathsInput.value)
    if (excludeUserInput.value) searchParams.set("exclude",excludeUserInput.value)
    url.search = searchParams.toString()
    shareInput.value = url
    shareWrapper.classList.add("-active")
}

const getUrlParams = () => {
    const urlParams = new URLSearchParams(window.location.search)
    console.log(urlParams.get("ids"))
    if (urlParams) return true
}

createMap()

Cookies.get('user') ? excludeUserInput.value = Cookies.get('user') : null
const urlParams = new URLSearchParams(window.location.search)

if (urlParams.size > 0) {
    geopathsInput.value = urlParams.get("ids")
    excludeUserInput.value = urlParams.get("exclude")
    urlParams.delete("ids","exclude")
    window.history.replaceState('', '', window.location.pathname)
    btnSubmitActivity()
    main()
} else if (Cookies.get('geopaths')) {
    geopathsInput.value = Cookies.get('geopaths')
    btnSubmitActivity()
    main()
}

geopathsInput.addEventListener("focusout", btnSubmitActivity)
geopathsInput.addEventListener("keyup", btnSubmitActivity)
geopathsInput.addEventListener("keydown", valueFiltering)

document.addEventListener("click", e => {
    if (e.target.classList[0] == "js-hide") {
        
        const target = e.target.dataset.target
        const marker = document.querySelector(".c-markerIcon."+target)
        const listEl = document.querySelector("div."+target)

        marker.classList.add("-disabled")
        listEl.classList.add("-disabled")
        map.closePopup()        

        const disabledArray = []
        if (Cookies.get('disabledList')) disabledArray.push(Cookies.get('disabledList')) 
        disabledArray.push(target)
        Cookies.set('disabledList', disabledArray)
    }
    if (e.target.classList[0] == "js-show") {
        const target = e.target.dataset.target
        const marker = document.querySelector(".c-markerIcon."+target)
        const listEl = document.querySelector("div."+target)

        marker.classList.remove("-disabled")
        listEl.classList.remove("-disabled")
        map.closePopup()

        const caches = Cookies.get('disabledList').replaceAll(target,"")
        Cookies.set('disabledList', caches)
    }
})

btnSubmit.addEventListener("click", () => main())

btnClear.addEventListener("click", () => {
    Cookies.set('disabledList', "")
    Cookies.set('geopaths', "")
    Cookies.set('user', "")
    geopathsInput.value = ""
    excludeUserInput.value = ""
    setTimeout(() => {
        location.reload()
    }, 200)    
    shareWrapper.classList.remove("-active")
})

btnCopyLink.addEventListener("click", () => {
    console.log(shareInput.value)
    navigator.clipboard.writeText(shareInput.value)

    const tooltip = shareWrapper.querySelector('.c-tooltip')

    tooltip.classList.add('-visible')
    window.setTimeout(() => {
        tooltip.classList.remove('-visible')
    }, 1500)
})

window.onkeydown = (event) => {
    if (event.key === 'Enter') {
        btnSubmit.click()
    }
}